import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import LandingPage from "./routes/pages/landing";
import { ROUTE_PATHS } from "./routes/paths/route-paths";

function App() {
  const router = createBrowserRouter([
    {
      path: ROUTE_PATHS.LANDING_PAGE,
      element: <LandingPage />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
