import React from "react";
import ScrollToTopButton from "../../../components/atoms/scrollTopButton";
import LandingAnimateSplash from "../../../components/molecules/LandingAnimateSplash";
import ContentBlock from "../../../components/molecules/contentBlock";
import LanguageSwitcher from "../../../components/molecules/languageSwitcher";

const LandingPage: React.FC = () => {
  return (
    <div className="font-rubik">
      <LandingAnimateSplash />
      <ContentBlock />
      <LanguageSwitcher />
      <ScrollToTopButton />
    </div>
  );
};

export default LandingPage;
