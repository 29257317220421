import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface ILinks {
  text: string;
  link?: string;
}

interface IMiddleSection {}

type TMiddleSectionProps = IMiddleSection & HTMLAttributes<HTMLDivElement>;

const MiddleSection: React.FC<TMiddleSectionProps> = (props) => {
  const { t } = useTranslation();
  const { ...otherProps } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const LINKS: ILinks[] = [
    {
      text: "TXT_Home_service",
      link: "https://www.google.com",
    },
    {
      text: "TXT_Home_where",
      link: "https://www.google.com",
    },
    {
      text: "TXT_Home_pricing",
      link: "https://www.google.com",
    },
    {
      text: "TXT_Home_support",
      link: "https://www.google.com",
    },
  ];

  return (
    <div className="flex flex-1 flex-col justify-between items-center">
      <div className="text-[2.625rem] laptop:text-5xl font-bold text-white ">
        {t("TXT_Home_about")}
      </div>

      {/* mobile version  */}
      <div className="flex flex-col w-full gap-4 pb-5 my-16 laptop:hidden border-b-2 border-[#666666]">
        {LINKS.map((link, index) => {
          if (!expanded && index !== 0) {
            return null;
          }

          return (
            <div
              key={index}
              className={`flex justify-between items-center text-lg font-bold text-secondaryDark px-5 transition-all duration-500 ease-in-out ${
                !expanded && index !== 0
                  ? "h-0 opacity-0"
                  : "h-auto opacity-100"
              }`}
            >
              {t(link.text)}
              {index === 0 && (
                <>
                  {expanded ? (
                    <img
                      onClick={handleClick}
                      src={require("../../../assets/images/menu-arrow-up.png")}
                    />
                  ) : (
                    <img
                      onClick={handleClick}
                      src={require("../../../assets/images/menu-arrow-down.png")}
                    />
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* desktop version */}
      <div className="min-w-[60vw] hidden laptop:flex justify-between items-center mt-14 mb-24 laptop:mb-32">
        {LINKS.map((link, index) => {
          return (
            <React.Fragment key={index}>
              <div
                // href={link.link}
                // target="_blank"
                className="flex text-2xl font-bold text-secondaryDark hover:text-white transition-all duration-300 ease-in-out"
              >
                {t(link.text)}
              </div>
              {index !== LINKS.length - 1 && (
                <div className="w-[1px] h-[30px] bg-secondaryDark"></div>
              )}
            </React.Fragment>
          );
        })}
      </div>

      <div className="font-bold text-white text-center text-5xl laptop:text-8xl opacity-50">
        {t("TXT_Home_coming_soon")}
      </div>
    </div>
  );
};

export default MiddleSection;
