import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import BottomSection from "../bottomSection";
import MiddleSection from "../middleSection";
import TopSection from "../topSection";

interface IContentBlock {}

type TContentBlockProps = IContentBlock & HTMLAttributes<HTMLDivElement>;

const ContentBlock: React.FC<TContentBlockProps> = (props) => {
  const { t } = useTranslation();

  const { ...otherProps } = props;
  return (
    <div
      className="flex flex-col gap-24 laptop:gap-40 mx-[10%] laptop:mx-[15%] my-16 bg-black min-h-[100vh] h-full"
      {...otherProps}
    >
      {/* top section */}
      <TopSection />

      {/* middle section */}
      <MiddleSection />

      {/* bottom section */}
      <BottomSection />
    </div>
  );
};

export default ContentBlock;
