import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { MdArrowUpward } from "react-icons/md";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <div onClick={scrollToTop}>
          <div
            className="group fixed bottom-5 right-5 rounded-full transition-all duration-500 ease-in-out cursor-pointer z-30"
            onClick={scrollToTop}
          >
            <IconContext.Provider
              value={{
                className:
                  "fill-[#666666] group-hover:fill-mainBlue transition-all duration-500 ease-in-out",
              }}
            >
              <MdArrowUpward size={30} />
            </IconContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
