import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import ListPoint from "../../atoms/listPoint";

interface ITopSection {}

type TTopSectionProps = ITopSection & HTMLAttributes<HTMLDivElement>;
const LIST1 = ["TXT_Home_list1", "TXT_Home_list2", "TXT_Home_list3"];

const TopSection: React.FC<TTopSectionProps> = (props) => {
  const { t } = useTranslation();
  const { ...otherProps } = props;
  return (
    <div className="flex flex-col laptop:flex-row  flex-1 gap-10">
      <div className="flex flex-col flex-1 gap-5 laptop:gap-8">
        <h1 className="text-3xl text-center laptop:text-left laptop:text-4xl text-mainBlue">
          {t("TXT_Home_title")}
        </h1>
        <h3 className="text-sm laptop:text-xl text-white leading-9">
          {t("TXT_Home_description")}
        </h3>

        {LIST1.map((item, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-4">
              <ListPoint />
              <div className="flex text-xs laptop:text-lg font-bold text-white">
                {t(item)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-1 object-contain self-center laptop:self-start">
        <img
          className="flex laptop:hidden rounded-2xl"
          src={require("../../../assets/images/small_cover.png")}
          alt="small_cover"
        />
        <img
          className="hidden laptop:flex rounded-2xl"
          src={require("../../../assets/images/big_cover.png")}
          alt="big_cover"
        />
      </div>
    </div>
  );
};

export default TopSection;
