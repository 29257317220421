import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface ILangOption {
  langCode: string;
  text: string;
}

const LANG_OPTIONS: ILangOption[] = [
  {
    langCode: "en",
    text: "EN",
  },
  {
    langCode: "tc",
    text: "中文",
  },
];

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language: any) => {
    i18n.changeLanguage(language);
  };
  const currentLanguage = i18n.language;

  return (
    <div className="absolute w-fit flex flex-col gap-2 bottom-32 right-0 z-10 text-white font-extrabold">
      {LANG_OPTIONS.map((option) => {
        const isActive = currentLanguage === option.langCode;
        return (
          <div
            className="flex flex-row gap-2 justify-end items-center text-xl cursor-pointer"
            key={option.langCode}
          >
            <button onClick={() => changeLanguage(option.langCode)}>
              {option.text}
            </button>
            <div
              className={twMerge(
                "w-10 h-3 transition-all duration-300 ease-in-out",
                isActive ? "bg-white" : ""
              )}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export default LanguageSwitcher;
