import React, { HTMLAttributes, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, toast } from "react-toastify";

interface IContactUsForm {}

type TContactUsFormProps = IContactUsForm & HTMLAttributes<HTMLFormElement>;

const ContactUsForm: React.FC<TContactUsFormProps> = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { t } = useTranslation();
  const { ...otherProps } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    // Submit the form programmatically
    if (formRef.current) {
      formRef.current.reset();
      toast.success("我們已收到你的寶貴意見！", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };
  return (
    <form ref={formRef} className="flex flex-col gap-8 w-full text-white">
      <div className="flex flex-col laptop:flex-row gap-4 justify-between items-center">
        <input
          type="text"
          placeholder={t("TXT_Home_contact_form_name")}
          className="flex flex-1 w-full bg-transparent border border-[#666666] rounded-xl p-4"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder={t("TXT_Home_contact_form_email")}
          className="flex flex-1 w-full bg-transparent border border-[#666666] rounded-xl p-4"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <textarea
        placeholder={t("TXT_Home_contact_form_message")}
        className="h-40 bg-transparent border border-[#666666] rounded-xl p-4 resize-none"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>

      <button
        disabled={!email || !name || !message}
        type="submit"
        onClick={handleClick}
        className={`w-full laptop:w-fit px-20 py-3 ml-auto bg-transparent text-lg font-medium text-mainBlue rounded-[4.125rem] p-2 border-mainBlue border-2 hover:text-black hover:bg-mainBlue transition-all duration-300 ease-in-out cursor-pointer ${
          !email || !name || !message
            ? "opacity-50 cursor-default hover:bg-transparent hover:text-mainBlue"
            : ""
        }`}
      >
        {t("TXT_Home_contact_form_send")}
      </button>
    </form>
  );
};

export default ContactUsForm;
