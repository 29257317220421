import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import ListPoint from "../../atoms/listPoint";
import ContactUsForm from "../contactUsForm";

interface IBottomSection {}

type TBottomSectionProps = IBottomSection & HTMLAttributes<HTMLDivElement>;
const QUESTIONS = [
  "TXT_Home_contact_question1",
  "TXT_Home_contact_question2",
  "TXT_Home_contact_question3",
];

const BottomSection: React.FC<TBottomSectionProps> = (props) => {
  const { t } = useTranslation();
  const { ...otherProps } = props;

  return (
    <div className="flex flex-col laptop:flex-row flex-1 gap-10 items-center">
      <div className="flex flex-col flex-1 gap-5 laptop:gap-6">
        <h1 className="text-4xl text-center laptop:text-left text-mainBlue">
          {t("TXT_Home_contact")}
        </h1>
        <div>
          <h3 className="text-sm laptop:text-xl text-white ">
            {t("TXT_Home_contact_description1")}
          </h3>
          <h3 className="text-sm laptop:text-xl text-white ">
            {t("TXT_Home_contact_description2")}
          </h3>
        </div>

        {QUESTIONS.map((item, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-4">
              <ListPoint />
              <div className="flex text-xs laptop:text-lg font-bold text-mainYellow">
                {t(item)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-1 w-full">
        {/* form section */}
        <ContactUsForm />
      </div>
    </div>
  );
};

export default BottomSection;
