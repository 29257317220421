import React, { HTMLAttributes } from "react";

interface IListPoint {}

type TListPointProps = IListPoint & HTMLAttributes<HTMLDivElement>;

const ListPoint: React.FC<TListPointProps> = (props) => {
  const { ...otherProps } = props;

  return (
    <div
      className="flex shrink-0 w-3 h-3 laptop:w-5 laptop:h-5 bg-mainBlue rounded-full"
      {...otherProps}
    ></div>
  );
};

export default ListPoint;
